var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"flex flex-wrap xl:flex-nowrap px-2 md:px-3 py-2 bg-white rounded"},[_c('td',{staticClass:"w-[15%] sm:w-[10%] xl:w-[8%]",class:[
      {
        'py-1': !_vm.mobileRowExpanded,
        'pt-1': _vm.mobileRowExpanded,
      },
    ]},[_c('div',{staticClass:"bg-transparent relative",class:[
        {
          'rounded-l': !_vm.mobileRowExpanded,
          'rounded-tl': _vm.mobileRowExpanded,
        },
      ]},[(_vm.user.profilePicture)?_c('img',{staticClass:"w-[32px] md:w-[43px] rounded",attrs:{"src":_vm.user.profilePicture,"alt":_vm.fullName}}):_c('img',{staticClass:"w-[32px] md:w-[43px] rounded",attrs:{"src":require("@/assets/profile_photo_outline.png"),"alt":_vm.fullName}})])]),_c('td',{staticClass:"flex w-[85%] sm:w-[90%] md:w-[50%] xl:w-[22%]",class:[
      {
        'pt-1': _vm.mobileRowExpanded,
      },
    ]},[_c('div',{staticClass:"flex bg-transparent items-center flex-1 rounded-r"},[_c('div',{staticClass:"flex flex-col bg-transparent sm:pr-1"},[_c('router-link',{staticClass:"inline-block hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 group ease-out",attrs:{"to":`/group/${_vm.group.slug}/profile/${_vm.user.slug}`}},[_c('h6',{staticClass:"truncate font-semibold text-gray-900 text-sm sm:text-base group-hover:text-primary whitespace-normal",attrs:{"title":_vm.fullName}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")])]),_c('span',{staticClass:"text-gray-800 text-xs font-normal"},[_c('IconLocation',{staticClass:"inline-block w-[12px]"}),_vm._v(" "+_vm._s(_vm.getFormattedCity())+" ")],1)],1),_c('div',{staticClass:"md:hidden bg-gray-100 w-[30px] h-[30px] mr-2 md:mr-4 flex rounded animation-focus-default ml-auto items-center justify-center",on:{"click":_vm.toggleMobileRow}},[_c('span',{staticClass:"inline-block transition-transform duration-200 ease transform text-gray-900",class:[
            {
              'rotate-180': _vm.mobileRowExpanded,
            },
          ]},[_c('Chevron',{staticClass:"w-3 h-3"})],1)])])]),_c('td',{staticClass:"w-1/2 xl:w-[26%] sm:order-3 md:order-5 xl:order-none bg-transparent md:flex items-center sm:pt-2 xl:pt-0"},[_c('h6',{staticClass:"text-gray-900 text-sm font-normal md:block sm:px-1 flex flex-col truncate",class:[
        {
          hidden: !_vm.mobileRowExpanded,
          'py-2': _vm.mobileRowExpanded,
        },
      ],attrs:{"title":_vm.user.email}},[(_vm.mobileRowExpanded)?_c('span',{staticClass:"text-gray-500 text-xs font-semibold"},[_vm._v(" Email ")]):_vm._e(),(!_vm.user.isManagedByAdmin)?_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]):_vm._e()])]),_c('td',{staticClass:"bg-transparent w-1/2 xl:w-[22%] sm:order-4 md:order-6 xl:order-none md:flex items-center sm:pt-2 xl:pt-0"},[_c('div',{staticClass:"sm:px-1 md:flex",class:[
        {
          hidden: !_vm.mobileRowExpanded,
          'py-2': _vm.mobileRowExpanded,
        },
      ]},[_c('div',{staticClass:"flex flex-col md:flex-row items-start md:items-center justify-center"},[_c('span',{staticClass:"text-gray-500 text-xs font-semibold leading-[140%]"},[_vm._v(" Joined: ")]),_c('span',{staticClass:"text-gray-900 text-sm font-normal leading-[140%]",attrs:{"title":_vm.readableDate}},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")])])])]),_c('td',{staticClass:"w-full md:w-[40%] xl:w-[22%] order-5 md:order-none md:flex items-center",class:[
      {
        'pb-2': _vm.mobileRowExpanded,
      },
    ]},[_c('div',{staticClass:"ml-auto md:flex justify-end w-full bg-transparent",class:[
        {
          hidden: !_vm.mobileRowExpanded,
          'py-1 rounded-b': _vm.mobileRowExpanded,
        },
      ]},[_c('Dropdown',{staticClass:"min-w-[146px] rounded border border-gray-300 shadow-drop border-solid",attrs:{"custom-class":"h-[42px]","placeholder-class":"text-sm","scheme":"transparent","options":_vm.dropdownOptions,"selectedOption":_vm.selectedDropdownOption},on:{"selection":_vm.changeStatus}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="printView">
    <h2 class="mb-4">

      {{ group.name }}
    </h2>
    <span v-html="group.description"></span>

    <div class="border-t-2 border-solid border-gray-500 mt-8 mb-4"></div>

    <PrintViewSection :sectionMembers="adminProfiles">
      <template v-slot:sectionName>
        ADMIN
      </template>
    </PrintViewSection>

    <div class="border-t-2 border-solid border-gray-500 mb-4"></div>
    <PrintViewSection :sectionMembers="nonAdminProfiles">
      <template v-slot:sectionName>
        MEMBERS
      </template>
    </PrintViewSection>

    <div v-if="inMemoriamProfiles?.length">
      <div class="border-t-2 border-solid border-gray-500 mb-4"/>
      <PrintViewSection :sectionMembers="inMemoriamProfiles">
        <template v-slot:sectionName>
          IN MEMORIAM
        </template>
      </PrintViewSection>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PrintViewSection from '@/components/PrintViewSection.vue';

export default {
  name: 'PrintView',
  components: {
    PrintViewSection,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      'group',
      'profiles',
    ]),

    currentRouteName() {
      return this.$route.name;
    },
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
    adminProfiles() {
      return this.profiles.filter((prof) => {
        return prof.status === 'active' && prof.isGroupAdmin;
      });
    },
    nonAdminProfiles() {
      return this.profiles.filter((prof) => {
        return prof.status === 'active' && !prof.isGroupAdmin;
      });
    },
    inMemoriamProfiles() {
      return this.profiles.filter((prof) => {
        return prof.isDeceased;
      });
    },
  },
  methods: {

  },
  watch: {
  },
  beforeCreate() {
  },

  created() {


  },
};
</script>

<style lang="scss">
.profile {
  page-break-inside: avoid;
  page-break-after: auto
}
</style>

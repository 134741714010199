<template>
  <tr class="flex flex-wrap xl:flex-nowrap px-2 md:px-3 py-2 bg-white rounded">
    <td
      class="w-[15%] sm:w-[10%] xl:w-[8%]"
      :class="[
        {
          'py-1': !mobileRowExpanded,
          'pt-1': mobileRowExpanded,
        },
      ]"
    >
      <div
        class="bg-transparent relative"
        :class="[
          {
            'rounded-l': !mobileRowExpanded,
            'rounded-tl': mobileRowExpanded,
          },
        ]"
      >
        <img
          v-if="user.profilePicture"
          class="w-[32px] md:w-[43px] rounded"
          :src="user.profilePicture"
          :alt="fullName"
        />
        <img
          v-else
          class="w-[32px] md:w-[43px] rounded"
          src="@/assets/profile_photo_outline.png"
          :alt="fullName"
        />
      </div>
    </td>

    <td
      class="flex w-[85%] sm:w-[90%] md:w-[50%] xl:w-[22%]"
      :class="[
        {
          'pt-1': mobileRowExpanded,
        },
      ]"
    >
      <div class="flex bg-transparent items-center flex-1 rounded-r">
        <div class="flex flex-col bg-transparent sm:pr-1">
          <router-link
            :to="`/group/${group.slug}/profile/${user.slug}`"
            class="inline-block hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 group ease-out"
          >
            <h6
              class="truncate font-semibold text-gray-900 text-sm sm:text-base group-hover:text-primary whitespace-normal"
              :title="fullName"
            >
              {{ fullName }}
            </h6>
          </router-link>
          <span class="text-gray-800 text-xs font-normal">
            <IconLocation class="inline-block w-[12px]" />
            {{ getFormattedCity() }}
          </span>
        </div>
        <div
          @click="toggleMobileRow"
          class="md:hidden bg-gray-100 w-[30px] h-[30px] mr-2 md:mr-4 flex rounded animation-focus-default ml-auto items-center justify-center"
        >
          <span
            class="inline-block transition-transform duration-200 ease transform text-gray-900"
            :class="[
              {
                'rotate-180': mobileRowExpanded,
              },
            ]"
          >
            <Chevron class="w-3 h-3"></Chevron>
          </span>
        </div>
      </div>
    </td>

    <td
      class="w-1/2 xl:w-[26%] sm:order-3 md:order-5 xl:order-none bg-transparent md:flex items-center sm:pt-2 xl:pt-0"
    >
      <h6
        class="text-gray-900 text-sm font-normal md:block sm:px-1 flex flex-col truncate"
        :class="[
          {
            hidden: !mobileRowExpanded,
            'py-2': mobileRowExpanded,
          },
        ]"
        :title="user.email"
      >
        <span v-if="mobileRowExpanded" class="text-gray-500 text-xs font-semibold"> Email </span>
        <span class="truncate" v-if="!user.isManagedByAdmin">
          {{ user.email }}
        </span>
      </h6>
    </td>

    <td
      class="bg-transparent w-1/2 xl:w-[22%] sm:order-4 md:order-6 xl:order-none md:flex items-center sm:pt-2 xl:pt-0"
    >
      <div
        class="sm:px-1 md:flex"
        :class="[
          {
            hidden: !mobileRowExpanded,
            'py-2': mobileRowExpanded,
          },
        ]"
      >
        <div class="flex flex-col md:flex-row items-start md:items-center justify-center">
          <span class="text-gray-500 text-xs font-semibold leading-[140%]"> Joined: </span>
          <span class="text-gray-900 text-sm font-normal leading-[140%]" :title="readableDate">
            {{ formattedDate }}
          </span>
        </div>
      </div>
    </td>

    <td
      class="w-full md:w-[40%] xl:w-[22%] order-5 md:order-none md:flex items-center"
      :class="[
        {
          'pb-2': mobileRowExpanded,
        },
      ]"
    >
      <div
        class="ml-auto md:flex justify-end w-full bg-transparent"
        :class="[
          {
            hidden: !mobileRowExpanded,
            'py-1 rounded-b': mobileRowExpanded,
          },
        ]"
      >
        <Dropdown
          class="min-w-[146px] rounded border border-gray-300 shadow-drop border-solid"
          custom-class="h-[42px]"
          placeholder-class="text-sm"
          scheme="transparent"
          :options="dropdownOptions"
          :selectedOption="selectedDropdownOption"
          @selection="changeStatus"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Dropdown from '@/components/Dropdown.vue';
import Chevron from '@/assets/chevron.svg';
import IconLocation from '@/components/svgComponents/IconLocation.vue';
import { getISODateToFormatted, readableDate } from '../filters';

export default {
  name: 'AdminUserTableRow',
  components: {
    IconLocation,
    Dropdown,
    Chevron,
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedDropdownOption = this.dropdownOptions.filter(
      (option) => option.value === this.user.status,
    )[0];
  },
  props: {
    /**
     * Group user to display in row
     */
    user: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['group']),
    fullName() {
      return `${this.user.firstName} ${this.user.lastName} ${this.previousName}`;
    },
    previousName() {
      if (this.user.previousFirstName && this.user.previousLastName) {
        return ` (${this.user.previousFirstName} ${this.user.previousLastName})`;
      }
      if (this.user.previousFirstName) {
        return ` (${this.user.previousFirstName})`;
      }
      if (this.user.previousLastName) {
        return ` (${this.user.previousLastName})`;
      }
      return '';
    },
    formattedDate() {
      return getISODateToFormatted(this.user.joinedDate);
    },
    readableDate() {
      return readableDate(this.user.joinedDate);
    },
  },
  data() {
    return {
      selectedDropdownOption: {},
      mobileRowExpanded: false,
      dropdownOptions: [
        {
          label: 'Active',
          value: 'active',
          iconColorSmall: 'bg-green-500',
        },
        {
          label: 'Pending',
          value: 'pending',
          iconColorSmall: 'bg-yellow-500',
        },
        {
          label: 'Suspended',
          value: 'suspended',
          iconColorSmall: 'bg-red-700',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['updateUser', 'fetchProfiles']),
    toggleMobileRow() {
      this.mobileRowExpanded = !this.mobileRowExpanded;
    },
    changeStatus(option) {
      this.selectedDropdownOption = option;
      this.updateUser({
        groupSlug: this.$route.params.group,
        profileSlug: this.user.slug,
        user: {
          ...this.user,
          status: option.value,
        },
        isAdmin: true,
      }).then(() => {
        this.fetchProfiles({ groupSlug: this.$route.params.group });
      });
      // TODO: decide on how to handle error in UI
    },
    getFormattedCity() {
      const city = this.user.city || '';
      const state = this.user.state ? `, ${this.user.state}` : '';
      return `${city}${state}` || 'N/A';
    },
  },
};
</script>

<style lang="scss" scoped>
.status-circle {
  top: 0.25rem;
  left: 0.25rem;
}
</style>
